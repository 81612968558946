import Link from 'next/link';

const isDev = process.env.NODE_ENV === 'development';

export default function HomePage() {
  if (isDev) {
    return (
      <div>
        <Link href="/a/pro">Release Page Pro</Link>
        <br />
        <Link href="/a/free">Release Page Free</Link>
        <br />
        <Link href="/artist/pro">Artist Page Pro</Link>
      </div>
    );
  }
  return 'IMD Release Pages UI';
}
